import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { format } from "date-fns"
import queryString from "query-string"
import BirthdayPicker from "./components/birthday-picker"
import Spinner from "./components/spinner"
import { FormType } from "../../types"
import { postLead } from "../../api/leads"
import { getUniversalId } from "../../api/uinversal-id"
import useSiteMetadata from "../../hooks/use-site-metadata"
import lookUpState from "../../../utils/look-up-state"
import { trimmedGaCookieValue } from "../../../utils/look-up-ga-cookie"

type FormProps = {
  form: FormType
  [key: string]: any
}

type FormData = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  income: string
  state: string
  zipCode: string
  dateOfBirth: string
}

const LeadForm = ({ form, ...props }: FormProps) => {
  const search = props?.location?.search
  const parsedSearch = queryString.parse(search)
  const { siteUrl, environment } = useSiteMetadata()
  const [stateData, setData] = useState({
    isSubmitting: false,
    zipCode: parsedSearch.zipCode ? `${parsedSearch.zipCode}` : ``,
    state: lookUpState(`${parsedSearch.zipCode || `0`}`),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const firstNameClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.firstName ? `border-red-500` : ``}`
  const lastNameClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.lastName ? `border-red-500` : ``}`
  const emailClasses = `mb-1 p-3 block w-full md:col-span-2 bg-inputBackground ${errors.email ? `border-red-500` : ``}`
  const phoneNumberClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.phoneNumber ? `border-red-500` : ``}`
  const incomeClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.income ? `border-red-500` : ``}`
  const stateClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.state ? `border-red-500` : ``}`
  const zipCodeClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.zipCode ? `border-red-500` : ``}`
  const dateOfBirthClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.dateOfBirth ? `border-red-500` : ``}`
  const consentClasses = `mt-2 ${errors.consent ? `border-red-500` : ``}`
  const buttonClasses = `p-3 w-2/3 lg:w-1/2 button ${stateData.isSubmitting ? `disabled` : `primary`}`

  const wrapGetUniversalId = async (data: FormData): Promise<string | undefined> => {
    try {
      const universalId = await getUniversalId({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        income: data.income,
        state: data.state,
        zipCode: data.zipCode,
        dateOfBirth: data.dateOfBirth,
        familySize: `1`,
        leadSourceDomain: siteUrl,
        environment: environment,
      })

      return universalId
    } catch (err) {
      window.datadogLogs?.logger.error(`submit universal id error:`, body)
    }

    return undefined
  }

  const onSubmit = async (data: FormData) => {
    setData({ ...stateData, isSubmitting: true })

    try {
      const result = await postLead({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        income: data.income,
        state: data.state,
        zipCode: data.zipCode,
        dateOfBirth: data.dateOfBirth,
        familySize: `1`,
        leadSourceDomain: siteUrl,
        environment: environment,
      })

      if (result) {
        // First get a univeral id for this lead, so it can be tracked with analytics
        const universalId = await wrapGetUniversalId(data)

        // GA event logging
        if (typeof marketingDataLayer !== `undefined`) {
          marketingDataLayer?.push({
            universalID: universalId,
          })

          marketingDataLayer?.push({
            event: `gaForm`,
            eventCategory: `Form`,
            eventAction: `lead-form-v2`,
            eventLabel: location.pathname,
          })
        }

        // Tealium event logging
        if (typeof utag !== `undefined`) {
          console.log(`Tealium is loaded`)

          utag.link({
            customer_birthdate: "", //Required
            customer_email: `${data.email}`, //Required
            customer_first_name: `${data.firstName}`, //Required
            customer_id: trimmedGaCookieValue(), //Required (RTRIM value of _ga Cookie)
            customer_last_name: `${data.lastName}`, //Required
            customer_name: "", //Required
            customer_phone_number: `${data.phoneNumber}`, //Required
            customer_postal_code: `${data.zipCode}`, //Required
            customer_state: `${data.state}`, //Required
            customer_type: "family", //Required
            universal_id: universalId,
            form_id: "", //Required
            form_name: "Inquiry Form", //Required
            tealium_event: "form_complete", //Required
          })
        }

        location.assign(`${form.destination}`)
      }

      setData({ ...stateData, isSubmitting: false })
    } catch (err) {
      setData({ ...stateData, isSubmitting: false })
      alert(`Your request was not sent successfully.  ${err.message}`)
    }
  }

  // Configure form values and validation on form register
  React.useEffect(() => {
    register(`dateOfBirth`, { required: true })

    const state = lookUpState(`${parsedSearch.zipCode || `0`}`)

    setData({
      ...stateData,
      state: state,
    })
    setValue(`state`, state)
  }, [register])

  return (
    <form id="lead-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
        <input {...register("firstName", { required: true })} type="text" className={firstNameClasses} placeholder="First Name" autoComplete="given-name" autoFocus />

        <input {...register("lastName", { required: true })} type="text" className={lastNameClasses} placeholder="Last Name" autoComplete="family-name" />

        <input {...register("email", { required: true })} type="email" className={emailClasses} placeholder="Email" autoComplete="email" />

        <input {...register("phoneNumber", { required: true })} type="tel" className={phoneNumberClasses} placeholder="Phone" autoComplete="tel" />

        <input {...register("income", { required: true })} type="number" className={incomeClasses} placeholder="Income Household" />

        <select
          {...register("state", { required: true })}
          className={stateClasses}
          autoComplete="address-level1"
          value={stateData.state}
          onChange={(e: { target: { value: any } }) => {
            setData({ ...stateData, state: e.target.value })
          }}
        >
          <option value="" disabled>
            Select a state
          </option>
          <option value="AL">Alabama</option>
          <option value="AR">Arkansas</option>
          <option value="AZ">Arizona</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="IA">Iowa</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="MA">Massachusetts</option>
          <option value="MD">Maryland</option>
          <option value="ME">Maine</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MO">Missouri</option>
          <option value="MS">Mississippi</option>
          <option value="MT">Montana</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="NE">Nebraska</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NV">Nevada</option>
          <option value="NY">New York</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VA">Virginia</option>
          <option value="VT">Vermont</option>
          <option value="WA">Washington</option>
          <option value="WI">Wisconsin</option>
          <option value="WV">West Virginia</option>
          <option value="WY">Wyoming</option>
        </select>

        <input
          {...register("zipCode", { required: true, maxLength: 5, minLength: 4 })}
          type="number"
          className={zipCodeClasses}
          placeholder="Zip Code"
          autoComplete="postal-code"
          defaultValue={stateData.zipCode}
          value={stateData.zipCode}
          onChange={(e: { target: { value: any } }) => {
            setData({ ...stateData, zipCode: e.target.value })
            setValue('zipCode', e.target.value)
          }}
        />

        <div className="md:col-span-2">
          <BirthdayPicker
            name="dateOfBirth"
            initialDate={undefined}
            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
            className={dateOfBirthClasses}
            placeholderText="Birth Date (mm/dd/yyyy)"
            autoComplete="bday"
            onChange={(date: Date) => {
              const value = date ? format(date, `MM/dd/yyyy`) : ``
              setValue(`dateOfBirth`, value)
            }}
            onTab={(e: Event) => {
              if (e.shiftKey) {
                document.querySelector(`input[name=zipCode]`)?.focus();
              }
              else {
                document.querySelector(`input[name=consent]`)?.focus();
              }
            }}
          />
        </div>
      </div>

      <div className="block">
        <div className="mt-6">
          <div>
            <label className="inline-flex items-top">
              <input {...register("consent", { required: true })} type="checkbox" className={consentClasses} />
              <span className="ml-2 text-sm">
                By clicking "View Quotes" below, I agree to receive calls and text messages (including by autodialer and prerecorded message) from medical-sharing.com regarding health coverage at my
                phone number above. This consent does not require me to purchase anything.
              </span>
            </label>
          </div>
        </div>
      </div>

      <div className="text-center mt-5">
        {Object.keys(errors).length > 0 && (
          <div className="bg-red-400 p-3 my-10 text-white rounded">{(errors.consent && Object.keys(errors).length === 1 && `Please accept the terms.`) || `Please fix errors in your form.`}</div>
        )}

        <button type="submit" className={buttonClasses} disabled={stateData.isSubmitting}>
          {stateData.isSubmitting && <Spinner className="float-left h-7 w-5 text-white" size={5} color="white" />}
          {form.submitText || `Submit`}
        </button>
      </div>
    </form>
  )
}

export default LeadForm
